import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { dracula } from "react-code-blocks";
import TachyonCodeBlock from "../../components/TachyonCodeBlock/TachyonCodeBlock";

import { stageStyles, contentStyles, headingStyles } from "./styles";

/* --color-neutral-muted: rgba(175,184,193,0.2);
  --color-neutral-subtle: rgba(234,238,242,0.5); */
const inlineCodeStyle = {background: "rgba(175,184,193,0.2)", padding: '4px'}
const tachyonCodeSnippet=`def fac(x) {
  if(x == 0) {
    return 1;
  } else {
    return x * fac(x - 1);
  }
}
System.print(fac(5));`
const Installation = () => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      mt={6}
    >
      <Box sx={stageStyles}>
        <Box sx={contentStyles} mt={4}>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ ...headingStyles, fontWeight: 'lighter'}}
          >
            Getting Started
          </Typography>
          <Typography
            variant="h5"
            align="justify"
            gutterBottom
            sx={headingStyles}
            mb={2}
          >
            Prerequisites
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom mb={2}>
            You need to have a modern C++ compiler like <code style={inlineCodeStyle}>clang</code>.
          </Typography>
          <Typography
            variant="h5"
            align="justify"
            gutterBottom
            sx={headingStyles}
            mb={2}
          >
            Download from github
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom mb={2}>
          </Typography>
          <TachyonCodeBlock
              text={`git clone https://github.com/nirvanasupermind/tachyon.git`}
              language="shell"
              showLineNumbers={false}
              theme={dracula}
              allowCopy={true}
            />

          <Typography
            variant="h5"
            align="justify"
            gutterBottom
            sx={headingStyles}
            mb={2}
          >
            Compile Tachyon Infrastructure
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom mb={2}>
            Go to the directory where <b><i>Tachyon</i></b> source code was downloaded. Use the
            command:
          </Typography>
          <TachyonCodeBlock
              text={`clang++ src/*.cpp -o tachyonc -std = c++11`}
              language="shell"
              showLineNumbers={false}
              theme={dracula}
              allowCopy={true}
            /> 
            Place <code style={inlineCodeStyle}>tachyonc</code> in a system directory so that it is accessible from command line:
            <ul>
              <li>
                <b>Mac: </b><code style={inlineCodeStyle}>/usr/local/bin</code>
              </li>
              <br></br>
              <li>
                <b>Ubuntu Linux: </b><code style={inlineCodeStyle}>/usr/local/bin</code>
              </li>
              {/* <br></br>
              <li>
                <b>Windows 11: </b><span>To be determined</span>  
              </li> */}
            </ul>

          <Typography
            variant="h5"
            align="justify"
            gutterBottom
            sx={headingStyles}
            mb={2}
          >
            Write your program in Tachyon
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom mb={2}>
            Use the following sample code and save it as <code style={inlineCodeStyle}>factorial.tachyon</code>. This
            sample app computes the factorial of a given number.
          </Typography>
          <TachyonCodeBlock 
              text={tachyonCodeSnippet}
              language="python"
              showLineNumbers={false}
              theme={dracula}
              allowCopy={true}
            />

          <Typography
            variant="h5"
            align="justify"
            gutterBottom
            sx={headingStyles}
            mb={2}
          >
            Transpile your program
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom mb={2}>
            Run the command: 
            
          </Typography>
          <TachyonCodeBlock 
              text={`tachyonc factorial.tachyon -o factorial.cpp`}
              language="shell"
              showLineNumbers={false}
              theme={dracula}
              allowCopy={true}
            />

          <Typography
            variant="h5"
            align="justify"
            gutterBottom
            sx={headingStyles}
            mb={2}
          >
            Compile the resulting C++ program
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom mb={2}>
            Use the command: 
          </Typography>
          <TachyonCodeBlock 
              text={`clang++ factorial.cpp -o factorial -std = c++11`}
              language="shell"
              showLineNumbers={false}
              theme={dracula}
              allowCopy={true}
            />

          <Typography
            variant="h5"
            align="justify"
            gutterBottom
            sx={headingStyles}
            mb={2}
          >
            Run your program
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom mb={2}>
            You can execute your factorial application now. The method will vary
            depending on your operating system,
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default Installation;
