import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tabs, Tab, IconButton, Stack, AppBar } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";

const TabbedNavigation = () => {
  const [value, setValue] = React.useState("/");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setSelectedTab = (val) => {
    setValue(val)
  }

  const tabsGutter = 4;

  const location = useLocation()

  useEffect(() => {
    //const parsedLoc = location.pathname.replace(/\W/g, " ").trim();
    setSelectedTab(location.pathname)
  }, [location]);

  return (
      <div>
        <AppBar position="fixed" sx={{boxShadow: 'none'}}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ background: "rgb(103,58,184)", color: "rgb(238,238,238)" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              sx={{ paddingTop: `0 ${tabsGutter}px` }}
              TabIndicatorProps={{
                sx: {
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, .2)",
                },
              }}
            >
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '125px' }}
                label="Home"
                component={Link}
                to="/"
                value="/"
              />
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '125px' }}
                label="Get Started"
                component={Link}
                to="/installation"
                value="/installation"
              />
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '125px' }}
                label="Reference"
                component={Link}
                to="/reference"
                value="/reference"
              />
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '125px' }}
                label="About"
                component={Link}
                to="/about"
                value="/about"
              />
            </Tabs>
            <IconButton
              size="large"
              sx={{ marginLeft: "32px", color: "inherit", paddingTop: '8px' }}
              target="_blank" href="https://github.com/nirvanasupermind/tachyon"
            >
              <GitHubIcon sx={{ fontSize: "1.8rem" }} />
            </IconButton>
          </Stack>
        </AppBar>
      </div>
  );
};
export default TabbedNavigation;
