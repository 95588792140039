import { Backdrop, Box, CircularProgress, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import rehypeHighlight from 'rehype-highlight'
import remarkGfm from 'remark-gfm'
// import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
// import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { contentStyles, stageStyles } from "./styles"


const Reference = () => {
  const [content, setContent] = useState("");
  const [showBusy, setShowBusy] = useState(false);

  useEffect(() => {
    setShowBusy(true)
    fetch(
      "https://raw.githubusercontent.com/nirvanasupermind/tachyon/main/REFERENCE.md"
    )
      .then((res) => res.text())
      .then((text) => setContent(text))
      .finally(() => setShowBusy(false));
  }, []);
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      mt={6}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBusy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={stageStyles}>
        <Box sx={contentStyles}>
          <ReactMarkdown rehypePlugins={[rehypeHighlight]} remarkPlugins={[remarkGfm]} children={content} 
          components={{
            h2: ({ node, ...props }) => (
              // eslint-disable-next-line
              <h2 id={generateSlug(props.children[0])} {...props}></h2>
            ),
            // code: ({node, inline, className, children, ...props}) => {
            //   const match = /language-(\w+)/.exec(className || '')
            //   return !inline && match ? (
            //     <SyntaxHighlighter
            //       {...props}
            //       children={String(children).replace(/\n$/, '')}
            //       style={dark}
            //       language={match[1]}
            //       PreTag="div"
            //     />
            //   ) : (
            //     <pre {...props} className={className} style={{
            //       padding: '16px',
            //       overflow: 'auto',
            //       fontSize: '85%',
            //       lineHeight: 1.45,
            //       color: '#1F2328',
            //       backgroundColor: '#f6f8fa',
            //       borderRadius: '6px'
            //   }}>
            //       {children}
            //     </pre>
            //   )
            // }
          }} />
        </Box>
      </Box>
    </Stack>
  );
};

const generateSlug = (string) => {
  let str = string.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
    
  return str;
};

export default Reference;
