import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { stageStyles, contentStyles, headingStyles } from "./styles";

const descriptionContainerStyle =  {
  marginLeft: "auto",
  marginRight: "auto",
  paddingLeft: "0px",
  paddingRight: "0px",
  display: "flex",
  maxWidth: "61.5rem",
  marginTop: "1.25rem",
  flexDirection: "row"
};

const imgStyle = {
  display: "block",
  height: "auto",
  maxHeight: "12rem",
  flex: "21% 0 0",
  width: "100%",
  marginRight: "4%"
};


const About = () => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      mt={6}
    >
      <Box sx={stageStyles}>
        <Box sx={contentStyles} mt={4}>
          <Typography variant="h3" align="center" gutterBottom sx={headingStyles}>
            Introducing Tachyon
          </Typography>
          <section style={descriptionContainerStyle}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/nirvana.png`} alt={'Nirvana Choudhury'}  style={imgStyle}/>
            <div>
          <Typography
            variant="body1"
            align="justify"
            gutterBottom
            mb={2}
          >
            Tachyon is a lightweight dynamic programming language meticulously
            crafted with two primary goals in mind: developer's productivity and
            performance.
          </Typography>
          <Typography
            variant="body1"
            align="justify"
            gutterBottom
            mb={2}
          >
            With a minimalist and elegant programming paradigm, Tachyon ensures
            that application developers can quickly grasp the language with an
            extremely low learning curve. It's specifically designed for
            high-performance applications in domains like machine learning,
            numerical computing, and mathematical simulation.
          </Typography>
          </div>
          </section>
          <Typography
            variant="body1"
            align="justify"
            gutterBottom
            mb={2}
          >
            But that's not all! Tachyon provides an efficient and fast runtime
            environment that delivers superior performance. It's transpiled to
            modern C++ using tagged unions to handle dynamic typing, and can
            seamlessly interact with C++ code.
          </Typography>
          <Typography variant="body1" align="justify" gutterBottom>
            Invented by Nirvana Choudhury, Tachyon is the culmination of a dream
            to create a dynamic and compiled language that empowers developers
            with both usability and speed. This makes it a great fit for
            applications in domains like machine learning, where both ease of
            use and performance are paramount.
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default About;
