import { Box } from "@mui/material";
import React from "react";
import { dracula } from "react-code-blocks";
import FeatureBlock from "../../components/FeatureBlock/FeatureBlock";
import TachyonCodeBlock from "../../components/TachyonCodeBlock/TachyonCodeBlock";
import {
  stageFoundationStyles,
  heroStyles,
  stageStyles,
  contentStyles,
  logoHeadingStyles,
  logoSvgStyles,
  tagLineStyles,
  homeTopStyles,
  homeTopHeadingStyles,
} from "./styles";

const tachyCodeSnippet = `var Vec2 = {
  mag: lambda(self) {
      return Math.sqrt(self.x * self.x + self.y * self.y);
  }
};

var vec2 = {x: 3, y: 4, proto: Vec2};
System.print(vec2.mag()); // 5`;

const Home = () => {
  return (
    // <Stack direction="column" justifyContent="center" alignItems="center">
    //   <Box sx={{ textAlign: "left" }}>This is the home view</Box>
    // </Stack>

    <Box sx={stageFoundationStyles}>
      <header style={heroStyles}>
        <Box sx={stageStyles}></Box>
        <Box sx={contentStyles}>
          <h1 style={logoHeadingStyles}>
            <svg
              height="1.5em"
              viewBox="-128 0 2000 512"
              title="Tachyon"
              style={{
                display: "inline-block",
                margin: "-0.25em 0px 0px",
                verticalAlgn: "middle",
                ...logoSvgStyles,
              }}
            >
              <g>
                <path
                  style={{ fill: "#FFC61B" }}
                  d="M255.996,512c-68.38,0-132.667-26.629-181.02-74.98c-5.669-5.669-5.669-14.862,0-20.533
		c5.669-5.669,14.862-5.669,20.533,0c42.867,42.869,99.863,66.476,160.488,66.476s117.62-23.608,160.488-66.476
		C459.351,373.62,482.96,316.624,482.96,256s-23.608-117.62-66.476-160.488c-42.869-42.869-99.863-66.476-160.488-66.476
		S138.376,52.644,95.509,95.512c-57.256,57.256-79.728,141.45-58.65,219.728c2.085,7.742-2.501,15.708-10.244,17.793
		c-7.738,2.085-15.708-2.501-17.793-10.244C-2.68,280.078-2.935,234.853,8.086,192.005C19.44,147.853,42.572,107.387,74.977,74.98
		C123.328,26.629,187.616,0,255.996,0s132.667,26.629,181.02,74.98c48.352,48.352,74.98,112.639,74.98,181.02
		s-26.629,132.667-74.98,181.02C388.663,485.371,324.376,512,255.996,512z"
                />
                <path
                  style={{ fill: "#FFC61B" }}
                  d="M255.996,446.518c-105.052,0-190.518-85.466-190.518-190.518S150.944,65.482,255.996,65.482
		c67.966,0,131.273,36.627,165.218,95.591c4,6.948,1.61,15.825-5.338,19.826c-6.947,4.001-15.825,1.61-19.826-5.338
		c-28.778-49.988-82.443-81.041-140.054-81.041c-89.042,0-161.482,72.44-161.482,161.482s72.44,161.482,161.482,161.482
		S417.478,345.042,417.478,256c0-8.018,6.5-14.518,14.518-14.518s14.518,6.5,14.518,14.518
		C446.514,361.052,361.048,446.518,255.996,446.518z"
                />
              </g>
              <circle
                style={{ fill: "#FEE187" }}
                cx="255.995"
                cy="255.996"
                r="105.981"
              />
              <path
                style={{ fill: "#FFC61B" }}
                d="M255.996,376.499c-66.444,0-120.499-54.055-120.499-120.499s54.055-120.499,120.499-120.499
	S376.495,189.556,376.495,256S322.439,376.499,255.996,376.499z M255.996,164.537c-50.433,0-91.463,41.031-91.463,91.463
	s41.031,91.463,91.463,91.463s91.463-41.031,91.463-91.463S306.429,164.537,255.996,164.537z"
              />

              <foreignObject
                fill="white"
                stroke="#fff"
                strokeWidth=""
                strokeDashoffset=""
                fillRule="nonzero"
                fontSize="270"
                fontFamily="Roboto"
                letterSpacing="0"
                wordSpacing="0"
                markerStart=""
                markerMid=""
                markerEnd=""
                id="svg_15"
                x="540"
                y="138"
                width="1290"
                height="407"
                style={{ color: "rgb(235, 235, 235)" }}
                className=""
              >
                <p
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    border: "none",
                    outline: "none",
                    fontFamily: "inherit",
                    fontSize: "18rem",
                    fontWeight: "lighter",
                    lineHeight: "1em",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  TACHYON
                </p>
              </foreignObject>
            </svg>
          </h1>
          <p style={tagLineStyles}>
            Fast &amp; elegant dynamic programming language
          </p>
        </Box>
      </header>

      <TachyonCodeBlock
        text={tachyCodeSnippet}
        language="javascript"
        showLineNumbers={false}
        theme={dracula}
      />

      <section style={homeTopStyles}>
        <h1 style={homeTopHeadingStyles}>Python's ease, C++'s speed</h1>
      </section>

      <FeatureBlock
        heading="High performance"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/high-performance.svg`}
        desc1={`Even though Tachyon is a dynamic programming language, it was designed for high performance computation required for applications in fields such as machine learning, numerical algebra and simulation, etc. Tachyon programs are transpiled to efficient C++ code so that application developers do not have to worry about low level performance tuning. All Tachyon applications are eventually compiled into highly efficient object code.`}
        desc2={``}
        odd={true}
      />

      <FeatureBlock
        heading="Easy to learn"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/easy-to-learn.svg`}
        desc1={`One of the core design principles of Tachyon is that it is easy to learn. Its extreme focus on simplicity, readability & coherence sets it apart from other dynamic programming languages. Tachyon code is also designed to be maintainable, much more so than traditional scripting languages. Tachyon was designed to make programming a joyful activity.`}
        desc2={``}
        odd={false}
      />

      <FeatureBlock
        heading="Developer Productivity"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/developer-productivity.svg`}
        desc1={`Tachyon boosts developer productivity many times beyond compiled or statically typed languages such as C, C++, and Java. Tachyon code is typically one-third to one-fifth the size of equivalent C++ or Java code. That means there is less to type, less to debug, and less to maintain.`}
        desc2={``}
        odd={true}
      />

      <FeatureBlock
        heading="Reusability"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/reusable.svg`}
        desc1={`Tachyon enables a prototype based object oriented programming model. In classic class-based languages such as Java, as systems grow, their original class structures often become inadequate. Tachyon’s prototypical inheritance allows behavior reuse where objects can leverage other objects in a chain of prototypes.`}
        desc2={``}
        odd={false}
      />

      <FeatureBlock
        heading="Multi-Threading"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/multithreading.svg`}
        desc1={`Tachyon supports efficient multi-threading designed for modern hardware. Unlike Python where Global Interpreter Lock renders multithreading practically unusable, Tachyon’s threading model takes full advantage of concurrency for a target hardware. If the processor has multiple cores, creating a new thread will cause it to be executed on an unused or less busy core.`}
        desc2={``}
        odd={true}
      />

      <FeatureBlock
        heading="Interoperability"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/interoperability.svg`}
        desc1={`Tachyon programs can be easily integrated with C++ based systems. This interoperability is bidirectional. A Tachyon program can be embedded in a C++ application using Tachyon’s flexible C++ API. Similarly a Tachyon program can invoke C++ library or application after they are wrapped with Tycon friendly data structures.`}
        desc2={``}
        odd={false}
      />

      <FeatureBlock
        heading="Code Organization"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/code-organization.svg`}
        desc1={`Tachyon provides a “namespace” like approach to organize implementation of a program. It utilizes the concept of the object itself to provide a mechanism to organize code in hierarchical units`}
        desc2={``}
        odd={true}
      />

      <FeatureBlock
        heading="Cross-platform"
        imageSrc={`${process.env.PUBLIC_URL}/assets/images/cross-platform.svg`}
        desc1={`Tachyon is designed to be cross-platform. Tachyon’s transpiler and associated tools are designed with ANSI C++ which can be compiled with modern C++ compilers for any compliant operating system.`}
        desc2={``}
        odd={false}
      />
    </Box>
  );
};

export default Home;
