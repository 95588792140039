import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const globalTheme = createTheme({
  palette: {
    background: {
      default: "#eaebef",
    },
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "rgba(35,0,255,1.0)",
    },
    error: {
      main: red.A400,
    },
    white: {
      main: "#fff",
    },
    dark_ink: {
      main: "rgba(74, 72, 85, 1.0)",
    },
    highlight_ink: {
      main: "rgba(117,109,218,1.0)",
    },
    faded_gray_ink: {
      main: "#9CA5AE",
    },
    card_footer_bg: {
      main: "#D4DBED"
    },
    logo_pink: {
      main: "#E7389D"
    },
    purple_ink: {
      main: "rgba(79,49,209,1.0)"
    }
  },  
});

const appTheme = createTheme({
  ...globalTheme,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          fontWeight: 600,
          borderRadius: 8.5,
          textTransform: "none",
          "&.MuiButton-contained": {
            backgroundColor: "#7158e2",
            "&:hover": {
              backgroundColor: "#836ee6",
            },
          },
          "&.MuiButton-outlined": {
            color: "#fff",
            borderColor: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            ">.MuiListItemIcon-root>.MuiSvgIcon-root": {
              color: globalTheme.palette.purple_ink.main,
            },
            color: globalTheme.palette.purple_ink.main,
            background: "transparent",
            ":hover": {
              color: globalTheme.palette.purple_ink.main,
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.3rem",
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: "1.3rem",
      fontWeight: 400,
      color: globalTheme.palette.dark_ink.main,
      letterSpacing: "0.5px",
      textTransform: "capitalize",
    },
  },
});

export default appTheme;