const featureBlockStyles = {
  marginLeft: "auto",
  marginRight: "auto",
  paddingLeft: "20px",
  paddingRight: "20px",
  display: "flex",
  maxWidth: "61.5rem",
  marginTop: "1.25rem",
};

const featureBlockStylesOdd = {
    flexDirection: "row-reverse",
}

const featureBlockStylesEven = {
    flexDirection: "row",
}

const imgStyleOdd = {
  marginLeft: "4%",
  marginRight: "0",
};

const imgStyleEven = {
  marginRight: "4%",
};

const imgStyle = {
  alignSelf: "center",
  display: "block",
  height: "auto",
  maxHeight: "10rem",
  flex: "21% 0 0",
  width: "100%",
};

const featureBlockHeading = {
    marginTop: 0,
    textAlign: 'left',
    fontSize: '220%',
    fontWeight: '300',
    letterSpacing: '.01em',
    lineHeight: '1.2',
    marginBottom: '0.5em',
    cursor: 'text',
    margin: '30px 0 10px',
    padding: 0,
    position: 'relative'
}

const FeatureBlock = ({ heading, imageSrc, desc1, desc2, odd }) => {
  return (
    <section style={(odd ? { ...featureBlockStyles, ...featureBlockStylesOdd } : { ...featureBlockStyles, ...featureBlockStylesEven })}>
      <img
        style={
          (odd ? { ...imgStyle, ...imgStyleOdd } : { ...imgStyle, ...imgStyleEven })
        }
        src={imageSrc}
        alt="metal"
        loading="lazy"
        width="54"
        height="54"
      />

      <div>
        <h2 style={featureBlockHeading}>{heading}</h2>
        <p>{desc1}</p>
        <p>{desc2}</p>
      </div>
    </section>
  );
};

export default FeatureBlock;