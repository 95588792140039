import { Box } from "@mui/material";
import { CodeBlock, CopyBlock } from "react-code-blocks";

const highlightContainerStyles = {
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0 5px 25px rgba(0,0,0,.5)',
  maxWidth: '50rem',
  marginBottom: '2.5rem',
  padding: '0',
  position: 'relative',
  marginTop: '1.25rem',
  fontFamily: 'source-code-pro,Menlo,Consolas,Monaco,Andale Mono,Courier New,monospace',
  fontSize: '.9rem',
  "&.MuiBox-root > span": {
    paddingLeft: '8px'
  },
  "&.MuiBox-root > div": {
    padding: '0.75rem'
  }
}


const TachyonCodeBlock = (props) => {
  let allowCopy = false
  if (props.allowCopy === true) {
    allowCopy = true
  }
  return (
    <Box sx={highlightContainerStyles}>
      {allowCopy === true ? (<CopyBlock {...props} />) : (<CodeBlock {...props} />)}
    </Box>
  );
}

export default TachyonCodeBlock