  export const stageStyles = {
    background: "#fff",
    height: "100%",
    left: "50%",
    margin: "0px",
    maxWidth: "none",
    padding: "0px",
    right: "50%",
    width: "100%",
  };
  
  export const contentStyles = {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "50rem",
    paddingLeft: "20px",
    paddingRight: "20px",
    transform: "translateZ(0)",
  };
  