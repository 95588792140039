import {
  Outlet,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Box, Stack } from "@mui/material";
import TabbedNavigation from "./pages/TabbedNavigation/TabbedNavigation";
import Home from "./pages/Home/Home";
import Installation from "./pages/Installation/Installation";
import Reference from "./pages/Reference/Reference";
import About from "./pages/About/About";

const footerStyle = {
  background: "rgb(242,242,242)",
  borderTop: "0.0625rem solid rgb(221,221,221)",
  color: "rgb(85,85,85)",
  overflow: "hidden",
  padding: "1.25rem",
  textAlign: "center",
  width: "100%",
};

function App() {
  return (
    <Router>
      <div>
        <Stack
          direction="column"
          sx={{
            background: "#fff",
            width: "100%",
            minHeight: "calc(100vh - 89px)",
          }}
        >
          <TabbedNavigation />
          <Outlet />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/installation" element={<Installation />} />
            <Route path="/reference" element={<Reference />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Stack>
        <Box sx={footerStyle}>
          <p style={{ lineHeight: 1 }}>
            Built by{" "}
            <a
              href="https://github.com/nirvanasupermind"
              target="_blank"
              rel="noopener noreferrer"
            >
              @nirvanasupermind
            </a>
            .
          </p>
        </Box>
      </div>
    </Router>
  );
}

export default App;
