export const stageFoundationStyles = {
  marginBottom: "4rem",
  overflow: "hidden",
  color: "rgb(68,68,68)",
};

export const heroStyles = {
  maxWidth: "none",
  width: "100%",
  marginTop: '6rem',
  marginLeft: "auto",
  marginRight: "auto",
  display: "block",
  overflow: "visible",
  padding: "0px",
  position: "relative",
  textAlign: "center",
};
export const stageStyles = {
  background: "rgb(103,58,184)",
  color: "rgb(238,238,238)",
  transform: "skewY(-6deg) scale(2.6) translate3d(-50%,22%,0)",
  height: "100%",
  left: "50%",
  margin: "0px",
  maxWidth: "none",
  padding: "0px",
  position: "absolute",
  right: "50%",
  transformOrigin: "0 100%",
  width: "100%",
};

export const contentStyles = {
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "50rem",
  paddingLeft: "20px",
  paddingRight: "20px",
  transform: "translateZ(0)",
};

export const logoHeadingStyles = {
  padding: "1.5rem 0 0",
  color: "#fff",
  textTransform: "uppercase",
  fontSize: "28px",
  cursor: "text",
  fontWeight: 700,
  margin: "30px 0 10px",
  position: "relative",
};

export const logoSvgStyles = {
  height: "auto",
  maxWidth: "600px",
  width: "100%",
};

export const tagLineStyles = {
  color: "#fff",
  display: "block",
  fontSize: "1.8rem",
  fontWeight: "300",
  lineHeight: "1.2",
  margin: "3rem 0 3.5rem",
  textAlign: "center",
};

export const homeTopStyles = {
    margin: '60px auto',
    maxWidth: '984px',
    paddingLeft: '20px',
    paddingRight: '20px'
}

export const homeTopHeadingStyles = {
    fontSize: '377%',
    fontWeight: 'lighter',
    letterSpacing: '.02em',
    textAlign: 'center',
    cursor: 'text',
    margin: '30px 0 10px',
    padding: 0,
    position: 'relative',
}